.cookieConsent {
    background: #3336DC !important;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    color: #fff !important;
    padding: 10px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    z-index: 1000 !important;
}

.cookieConsent__link {
    color: #EBEBEB !important;
    text-decoration: underline !important;
}

.cookieConsent__button {
    background: #EBEBEB !important;
    color: #3336DC !important;
    border-radius: 30px !important;
    padding: 10px 20px !important;
    margin-right: 10px !important;
    font-size: 13px !important;
    border: none !important;
    cursor: pointer !important;
}

.cookieConsent__declineButton {
    background: black !important;
    color: white !important;
    border-radius: 30px !important;
    padding: 10px 20px !important;
    font-size: 13px !important;
    border: none !important;
    cursor: pointer !important;
}
