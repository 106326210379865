.about {
    padding: 0px 50px 50px ; /* Daha fazla padding */
    padding-top: 100px; /* Header'ın altında yeterli boşluk bırakır */
    max-width: 900px; /* Daha geniş bir alan */
    margin: 0 auto;
    text-align: center;
    background-color: #f9f9f9; /* Hafif arka plan rengi */
    border-radius: 40px; /* Köşe yumuşatma */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
}

.about h1 {
    color: #3336DC;
    font-family: 'Urbanist', sans-serif;
    font-weight: bold;
    font-size: 2.5em; /* Başlık boyutunu artırdık */
    margin-bottom: 20px;
    text-align: left; /* Sol hizalama */
}

.about h2 {
    color: #3336DC;
    font-family: 'Urbanist', sans-serif;
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 15px;
    text-align: left; /* Sol hizalama */
}

.about p {
    color: #2B2A35;
    font-family: 'Urbanist', sans-serif;
    font-size: 1.2em; /* Paragraf boyutunu artırdık */
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: left; /* Sol hizalama */
}

.about .highlight {
    font-size: 1.4em; /* Belirginleştirilmiş yazı boyutu */
    font-weight: bold;
}

.about .section {
    margin-bottom: 30px; /* Bölümler arasında daha fazla boşluk */
}

.about .section:last-child {
    margin-bottom: 0;
}
