footer {
    background-color: #EBEBEB;
    color: #2B2A35;
    padding: 20px 50px 50px;
    text-align: center;
    border-top: 1px solid #ccc;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logo img {
    height: 100px;
    margin-bottom: 30px;
}

.footer-info p {
    margin: 20px 0;
}

.footer-info p a {
    margin: 0 5px; /* Linklerin arasındaki boşlukları eşitler */
    text-decoration: none;
    color: #3336DC; /* İstediğiniz renge göre ayarlayın */
}

.footer-info p a::after {
    content: '|';
    margin-left: 5px;
    color: #3336DC;
}

.footer-info p a:last-child::after {
    content: ''; /* Son linkten sonra '|' sembolü koymaz */
}
