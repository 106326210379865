.cards {
    display: flex;
    flex-direction: row; /* Card'lar yatayda sıralanacak */
    gap: 100px; /* Card'lar arasına 20px boşluk */
    justify-content: center; /* Card'ları yatayda ortalar */
    align-items: center; /* Card'ları dikeyde ortalar */
    padding: 40px 0; /* Üstten ve alttan 40px boşluk bırakır */
  }
  
  .card {
    background-color: #3336DC; /* Card arkaplan rengi */
    color: #fff; /* Metin rengi */
    border-radius: 10px; /* Köşe yuvarlama */
    padding: 20px; /* İç boşluk */
    display: flex;
    flex-direction: column;
    align-items: center; /* Sol hizalama */
    justify-content: center; /* Merkez hizalama */
    width: 200px; /* Kart genişliği */
    height: 300px; /* Kart yüksekliği */
    text-align: center; /* Metin hizalama */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge */
    position: relative; /* İkon konumlandırma için gerekli */
  }
  
  .card-icon {
    width: 40px; /* İkon genişliği */
    height: 40px; /* İkon yüksekliği */
    position: absolute;
    top: 20px; /* Üstten boşluk */
    left: 20px; /* Soldan boşluk */
  }
  
  .card p {
    font-size: 24px; /* Metin boyutu */
    font-weight: 600;
    line-height: 1.4; /* Satır yüksekliği */
    margin-top: 0; /* Üstten boşluk */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Merkezde hizalama */
    width: 80%; /* Genişlik ayarı */
  }
  