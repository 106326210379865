.DiscoverAnalysis {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 100px;
    background-color: #ebebeb;
  }
  
  .DiscoverAnalysis-content {
    display: flex;
    flex-direction: row-reverse; /* Sağ tarafa almak için */
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    width: 100%;
    background-color: #ffffff; /* Beyaz arka plan */
    border-radius: 40px; /* Köşe yumuşatma */
    padding: 20px; /* İçerik için padding */
  }
  
  .DiscoverAnalysis-text-content {
    flex: 2;
    text-align: left;
    font-size: 1.2em;
    margin-right: 20px;
  }
  
  .DiscoverAnalysis-text-content h2 {
    color: #2B2A35;
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 2.2em;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .DiscoverAnalysis-text-content p {
    color: #888888;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    margin-right: 50px;
    width: 100%;
  }
  
  .DiscoverAnalysis-image-content {
    flex: 1;
    text-align: right;
    margin-right: 50px;
  }
  
  .DiscoverAnalysis-image-content img {
    max-width: 100%;
    height: 450px;
    border-radius: 10px;
  }
  