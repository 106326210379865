.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 20px 50px;
    background-color: #fff;
}

.hero .text {
    flex: 1;
    padding-right: 20px; /* Sağ tarafta biraz boşluk bırakmak için */
}

.hero h1,
.hero p {
    color: #2B2A35;
    font-family: 'Urbanist', sans-serif;
    text-align: left;
}

.hero h1 {
    font-weight: 700;
    font-size: 3em; /* Daha küçük bir yazı tipi boyutu */
    margin-bottom: 10px;
    padding-left: 50px; /* Daha az padding */
    max-width: 100%; /* Başlığın çok geniş olmasını önlemek için */
}

.hero p {
    font-weight: 400;
    font-size: 1.5em;
    margin-bottom: 20px;
    padding-left: 50px; /* Daha az padding */
    max-width: 100%; /* Yazının çok geniş olmasını önlemek için */
}

.hero .image {
    flex: 1;
    text-align: right;
}

.hero img {
    width: 600px;
    height: 600px;
}
