.scroll-to-top {
    position: fixed;
    bottom: 100px;
    right: 50px;
    z-index: 1000;
}

.scroll-button {
    background-color: #3336DC;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    font-size: 20px; /* İkon boyutunu artırmak için font-size kullanın */
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.scroll-button:hover {
    opacity: 1;
    transform: scale(1.1);
}
