* {
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px; /* Sağdan ve soldan eşit padding */
    background-color: #fff;
    position: fixed; /* Header'ı sabit yapar */
    top: 0;
    width: 100%;
    z-index: 1000; /* Diğer içeriklerin üstünde kalmasını sağlar */
    transition: box-shadow 0.3s ease; /* Gölgenin yumuşak bir şekilde eklenmesini sağlar */
}

.logo img {
    height: 65px; /* Logo boyutunu ayarladık */
    margin-top: 10px;
    margin-bottom: 10px;
}

.navigation {
    display: flex;
    align-items: center;
    gap: 30px; /* Navigation içindeki öğeler arasında boşluk */
}

nav ul {
    list-style: none;
    display: flex;
    gap: 0px; /* List öğeleri arasında boşluk */
}

nav a {
    text-decoration: none;
    color: #2B2A35;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 20px; /* Köşeleri yuvarlatır */
    transition: background-color 0.3s, color 0.3s; /* Geçiş efektleri */
}

nav a.active {
    background-color: #3336DC;
    color: #fff;
}

.cta .button {
    background-color: #3336DC;
    color: #fff;
    text-decoration: none;
    border-radius: 20px;
    font-weight: bold;
}

/* Media Query'ler */
@media (max-width: 768px) {
    .header {
        padding: 0 20px; /* Daha küçük padding */
    }

    .navigation {
        flex-direction: column; /* Navigation içindeki öğeleri dikey hizala */
        gap: 10px; /* Daha küçük boşluk */
    }

    nav ul {
        flex-direction: column; /* List öğelerini dikey hizala */
        gap: 10px; /* Daha küçük boşluk */
    }

    nav a {
        padding: 8px 16px; /* Daha küçük padding */
    }

    .cta .button {
        padding: 8px 16px; /* Daha küçük padding */
    }
}

@media (max-width: 480px) {
    .logo img {
        height: 50px; /* Daha küçük logo boyutu */
    }

    .header {
        padding: 0 10px; /* Daha küçük padding */
    }

    nav a {
        padding: 6px 12px; /* Daha küçük padding */
        font-size: 14px; /* Daha küçük yazı boyutu */
    }

    .cta .button {
        padding: 6px 12px; /* Daha küçük padding */
        font-size: 14px; /* Daha küçük yazı boyutu */
    }
}
