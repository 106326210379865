/* src/Invest.css */
.invest-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 100px; /* Header yüksekliği kadar üst padding eklendi */
    padding-bottom: 100px;
    background-color: #fff;
}

.invest-title {
    font-size: 3em;
    margin-bottom: 10px;
    color: #333;
    font-family: 'Urbanist', sans-serif;
    text-align: center;
}

.invest-description {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #666;
    font-family: 'Urbanist', sans-serif;
    text-align: center;
    max-width: 800px;
}

.investor-logos {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: nowrap; /* Logoların yatay olarak hizalanmasını sağlar */
    overflow-x: auto; /* Taşan içerik için yatay kaydırma ekler */
}

.investor-logo {
    flex: 1 1 50%; /* Genişliği düzenlemek için oranı ayarlayın */
    max-width: 500px; /* Maksimum genişlik */
    height: 200px; /* Yükseklik ayarı */
    background-color: #EBEBEB; /* Boş logo kutusunun arka plan rengi */
    border-radius: 10px;
    padding: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px; /* Minimum genişlik ayarlayın */
    margin: 10px; /* Logolar arasına boşluk ekleyin */
    border: 2px dashed #ccc; /* Boş logolar için kesik çizgili border */
}

.investor-logo img {
    max-width: 100%;
    max-height: 100%; /* Yüksekliği sınırla */
    border-radius: 5px;
}

.contact-section {
    margin-top: 40px;
    text-align: center;
}

.contact-button {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1em;
    color: #fff;
    background-color: #3336DC;
    border-radius: 99px;
    text-decoration: none;
    font-family: 'Urbanist', sans-serif;
}

.contact-button:hover {
    background-color: #0056b3;
}
