.analysis {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    background-color: #ebebeb;
  }
  
  .analysis-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 40px;
    padding: 20px;
  }
  
  .analysis-text-content {
    flex: 2;
    text-align: left;
    font-size: 1.2em;
    margin-right: 20px;
  }
  
  .analysis-text-content h2 {
    color: #2B2A35;
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 2.2em;
    margin-bottom: 20px;
    margin-left: 30px;
    width: 100%;
  }
  
  .analysis-text-content p {
    color: #888888;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    margin-left: 30px;
    width: 100%;
  }
  
  .analysis-image-content {
    flex: 1;
    text-align: right;
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .analysis-image-content .lottie-animation {
    width: 350px;
    height: 500px;
  }
  