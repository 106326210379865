
.privacy-policy {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 100px; /* Bu değeri üstteki tab ekranının yüksekliğine göre ayarlayabilirsiniz */
  }
  
  .privacy-policy h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .privacy-policy p {
    color: #666;
    font-size: 14px;
    line-height: 1.6;
    white-space: pre-line; /* \n karakterlerinin yeni satır olarak gözükmesi için */
  }
  