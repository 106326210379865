body {
    font-family: 'Urbanist', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ebebeb;
}

h1, h2, p {
    margin: 0;
    padding: 0;
}
