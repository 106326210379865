.result {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 0px;
    background-color: #ebebeb;
}

.result-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    width: 100%;
    background-color: #ffffff; /* Beyaz arka plan */
    border-radius: 40px; /* Köşe yumuşatma */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
    padding: 20px; /* İçerik için padding */
}

.result-text-content {
    flex: 2;
    text-align: left;
    font-size: 1.2em;
    margin-right: 20px;
}

.result-text-content h2 {
    color: #2B2A35;
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    text-align:center;
    font-size: 2.2em;
    margin-bottom: 20px;
    width: 100%;
}

.result-text-content p {
    color: #888888;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    margin-right: 50px;
    width: 100%;
}

.result-image-content {
    flex: 1;
    text-align: left;
    margin-left: 50px;
}

.result-image-content img {
    max-width: 100%;
    height: 450px;
    border-radius: 10px;
}
