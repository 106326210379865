.screens {
    text-align: center;
    padding: 20px 100px;
    background-color: #EBEBEB; /* Arka plan rengini belirledik */
}

.screens-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.screens p {
    color: #2B2A35;
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    font-size: 2em;
    margin-bottom: 20px;
    line-height: 1.6;
}

.screens .highlight {
    color: #3336DC; /* Mavi renk */
}
