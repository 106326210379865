@font-face {
    font-family: 'Urbanist';
    src: url('./assets/fonts/Urbanist-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: url('./assets/fonts/Urbanist-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: url('./assets/fonts/Urbanist-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'Urbanist', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ebebeb;
}
